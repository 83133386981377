<template>
    <div v-if="canViewComponent && !isLoading" class="recommended-advisors mt-3">
        <div v-if="hasAdvisors && !isCobranded" class="slab p-2">
            <h4>Connect with a Virtuoso Travel Advisor</h4>
            <ul class="list-unstyled">
                <li v-for="(advisor, index) in recommendedAdvisors" :key="index" class="mt-2 d-flex d-md-block d-lg-flex">
                    <div v-if="advisor.advisorImageUrl" class="-headshot mb-2"><a :href="advisor.advisorUrl" @click="trackRecommendedAdvisorClick(advisor, index)"><img :src="advisor.advisorImageUrl" :alt="advisor.advisorName" class="rounded-circle d-block mx-auto" width="120" loading="lazy" /></a></div>
                    <div v-track-element-view="getRecommendedAdvisorTrackingData(advisor, index)" class="-contact-info">
                        <div><a :href="advisor.advisorUrl" class="-name" @click="trackRecommendedAdvisorClick(advisor, index)" v-html="advisor.advisorName"></a></div>
                        <div v-if="advisor.recommendedLabel" class="text--xsmall" v-html="advisor.recommendedLabel"></div>
                        <div class="mt-1">
                            <div v-if="advisor.advisorSpecialtyLabel" class="fw-bold text--small" v-html="advisor.advisorSpecialtyLabel"></div>
                            <div class="text--xsmall" v-html="advisor.agencyLocation"></div>
                            <div class="text--xsmall" v-html="advisor.agencyName"></div>
                            <advisor-contact-button-component :advisor="advisorContactButtonInfo(advisor)" :event-name="gaCategory" button-style="down-arrow" class="mt-1-half"></advisor-contact-button-component>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="mt-2"><a :href="advisorCatalogLinkFiltered">Or, find a different advisor</a></div>
        </div>
        <a v-else-if="!isNetworkUser && !isCobranded" class="btn btn-primary-emphasis btn-sm d-block" :href="advisorCatalogLink">Find a Travel Advisor</a>
        <a v-else-if="!isNetworkUser && isCobranded && !hideCobrandedButton" class="btn btn-primary-emphasis btn-sm d-block" :href="advisorCatalogLink">Contact Advisor to Book</a>
    </div>
</template>


<script lang="ts">
    import { AdvisorContactButton, RecommendedAdvisor, RecommendedAdvisorQuery } from "interfaces/advisor";
    import { ProductType } from "interfaces/enums";
    import { getRecommendedAdvisors } from "api/advisors";
    import { translateB2BFacetToConsumer } from "modules/product-search";
    import { isNetworkUser } from "modules/user-info";
    import { generateMediaServerImageUrl } from "services/images";
    import { parseAdvisorMeidFromUrlString, translateToConsumerUrl } from "services/products";
    import { isEmbeddedMode } from "services/utilities";
    import * as virtuosoSharedHeader from "virtuoso-shared-web-ui";
    import { PropType, computed, defineComponent } from "vue";
    import AdvisorContactButtonComponent from "vue-components/advisor-contact-button.vue";
    import { getRecommendedAdvisorTrackingData, trackRecommendedAdvisorClick } from "modules/analytics";


    // Test data for local testing
    /* const advisorsTestData = [
       {
           AdvisorName: "George Washington",
           AdvisorSpecialityLabel: "Ocean Cruising Specialist",
           AgencyLocation: "Seattle, WA United States",
           AgencyName: "Uniglobe Travel Center",
           EmailAddress: "george.washington@whitehouse.gov",
           ImageUrl: "https://virtuoso-prod.dotcms.cloud/dA/b40747253a/advisor-kristy-a.png",
           PhoneNumber: "888-111-2222",
           ProfilePageUrl: "/advisors/5805151/geraldine-hasting",
           TotalRecommendedPercent: 0,
           TotalReviews: 0
       },
       {
           AdvisorName: "Ian MacCulbury",
           AdvisorSpecialityLabel: "Ocean Cruising Specialist",
           AgencyLocation: "San Francisco, CA United States",
           AgencyName: "TravelOMG Inc.",
           EmailAddress: "ian@omg.com",
           ImageUrl: "https://virtuoso-prod.dotcms.cloud/dA/5151c70109/advisor-scott-l.png",
           PhoneNumber: "800-555-9999",
           ProfilePageUrl: "/advisors/16223565/ashley-stokes",
           TotalRecommendedPercent: 100,
           TotalReviews: 1
       },
   ]; */


    export default defineComponent({
        components: {
            AdvisorContactButtonComponent
        },
        // Data provided to advisor contact form
        provide() {
            return {
                searchFilters: computed(() => ({
                    destinationSpecialties: this.advisorSearchquery.ProductLocationCountry,
                    specialties: this.advisorSearchquery.InterestType}))
            };
        },

        props: {
            advisorQuery: {
                type: Object as PropType<RecommendedAdvisorQuery>,
                default: undefined
            },
            hideCobrandedButton: {
                type: Boolean,
                default: false
            },
            setRecommendedAdvisorIds: {
                type: Function,
                default: (): void => { /* if setRecommendedAdvisorsIds() is passed as undefined */ }
            }
        },

        data() {
            return {
                advisorCatalogLink: virtuosoSharedHeader.cobrandLink("/travel/advisors/search#"), // Used only in cobrand scenarios
                advisorCatalogLinkFiltered: virtuosoSharedHeader.cobrandLink("/travel/advisors/search#"),
                canViewComponent: (!isEmbeddedMode() && !isNetworkUser()),
                gaCategory: (this.advisorQuery.ProductTypeName === "Cruise") ? "Sailing Detail" : `${this.advisorQuery.ProductTypeName} Detail`,
                hasAdvisors: false,
                isCobranded: virtuosoSharedHeader.isCobranded(),
                isLoading: true,
                isNetworkUser: isNetworkUser(),
                recommendedAdvisors: [],
                advisorSearchquery: {}
            };
        },

        mounted: function (): void {
            this.advisorSearchquery = this.advisorQuery;

            if (this.canViewComponent) {
                // Special handling for US destination specialties, no "magic sub region" mapping (for now?)
                if (this.advisorSearchquery.ProductLocationCountry === "United States") {
                    this.advisorSearchquery.ProductLocationCountry = "United States - All";
                }

                // Translation from ship cruise type to advisor interest type
                if (this.advisorSearchquery.ProductTypeName === "Cruise" && this.advisorSearchquery.InterestType) {
                    this.advisorSearchquery.InterestType = `${this.advisorSearchquery.InterestType} Cruising`;
                }

                if (this.advisorSearchquery.ProductLocationCountry) {
                    this.advisorCatalogLinkFiltered += `${translateB2BFacetToConsumer("advisor_specilty_destination_for_facet")}=${encodeURIComponent(this.advisorSearchquery.ProductLocationCountry)}`;
                } else if (this.advisorSearchquery.InterestType) {
                    this.advisorCatalogLinkFiltered += `${translateB2BFacetToConsumer("advisor_interest_types")}=${encodeURIComponent(this.advisorSearchquery.InterestType)}`;
                }

                this.getAdvisors();
            }


        },

        methods: {
            getRecommendedAdvisorTrackingData,
            trackRecommendedAdvisorClick,
            advisorContactButtonInfo: function (advisor: RecommendedAdvisor): AdvisorContactButton {
                return {
                    company: advisor.agencyName,
                    companyId: advisor.companyId,
                    email: advisor.advisorEmail,
                    id: parseAdvisorMeidFromUrlString(advisor.advisorUrl),
                    name: advisor.advisorName,
                    phone: advisor.advisorPhone,
                    productType: ProductType.ADVISORS
                };
            },

            getAdvisors: function (): void {

                let firstRecommendedAdvisor: number = null;
                let lastRecommendedAdvisor: number = null;

                if (!this.isCobranded) { // If it's cobranded, we only ever show either a static button or nothing

                    getRecommendedAdvisors(this.advisorSearchquery)
                        .then((advisorsJSON) => {

                            // Test data for local testing, comment out the getRecommendedAdvisors().then() lines & the reject below
                            // const advisorsJSON = advisorsTestData;

                            if (advisorsJSON && advisorsJSON.length > 0) {
                                const validAdvisors: RecommendedAdvisor[] = [];

                                advisorsJSON.forEach((advisor) => {
                                    if (advisor.AdvisorName && advisor.ProfilePageUrl && advisor.EmailAddress && advisor.PhoneNumber && advisor.AgencyLocation && advisor.AgencyName) {
                                        validAdvisors.push({
                                            advisorEmail: advisor.EmailAddress,
                                            advisorImageUrl: (advisor.ImageUrl) ? generateMediaServerImageUrl(advisor.ImageUrl, { height: 140, width: 140 }) : "",
                                            advisorName: advisor.AdvisorName,
                                            advisorPhone: advisor.PhoneNumber,
                                            advisorSpecialtyLabel: advisor.AdvisorSpecialityLabel,
                                            advisorUrl: translateToConsumerUrl(advisor.ProfilePageUrl),
                                            agencyLocation: advisor.AgencyLocation,
                                            agencyName: advisor.AgencyName,
											companyId: advisor.CompanyId,
                                            recommendedLabel: (advisor.TotalReviews && advisor.TotalReviews > 0) ? `${advisor.TotalRecommendedPercent}% Recommended (${advisor.TotalReviews} Review${virtuosoSharedHeader.getPlural(advisor.TotalReviews)})` : "",
                                            totalReviews: advisor.TotalReviews
                                        });
                                    }
                                });

                                if (validAdvisors.length) {
                                    if (validAdvisors.length > 2) {
                                        validAdvisors.length = 2; // If, for some reason, it returns more than 2 advisors, trim it down to 2
                                    }
                                    this.recommendedAdvisors = validAdvisors;
                                    this.hasAdvisors = true;
                                    // there are at max 2 advisors
                                    if (this.recommendedAdvisors[0]) {
                                        firstRecommendedAdvisor = parseAdvisorMeidFromUrlString(this.recommendedAdvisors[0].advisorUrl);
                                    }
                                    if (this.recommendedAdvisors[1]) {
                                        lastRecommendedAdvisor = parseAdvisorMeidFromUrlString(this.recommendedAdvisors[1].advisorUrl);
                                    }
                                }

                            } else {
                                console.log("No recommended advisors available.");
                            }
                            this.isLoading = false;
                        },
                            (err: Error) => {
                                console.log("Error retrieving recommended advisors", err);
                                this.isLoading = false;
                            })
                        .finally(() => {
                            this.setRecommendedAdvisorIds(firstRecommendedAdvisor, lastRecommendedAdvisor);
                        });
                    // uncomment the setRecommendedAdvisorIds call below when Test data for local testing uncommented and reject is commented out
                    // this.setRecommendedAdvisorIds(firstRecommendedAdvisor, lastRecommendedAdvisor);
                } else {
                    if (this.hideCobrandedButton) {
                        this.canViewComponent = false;
                    }
                    this.isLoading = false;
                }
            }
        }
    });
</script>
