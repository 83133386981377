<template>
    <div v-if="isReady" class="product-detail -advisor">
        <div class="container px-lg-0">
            <div class="advisor-header context-dark">
                <div class="-photo">
                    <img :src="advisor.featuredImageUrl" :title="'Photo of ' + advisor.advisorName" :alt="'Photo of ' + advisor.advisorName">
                </div>
                <div class="-info">
                    <h1 class="mb-2">{{ advisor.advisorFirstName }}<br />{{ advisor.advisorLastName }}</h1>
                    <div class="-agency">{{ advisor.icPrefix }}<a :href="advisor.agencyUrl" v-html="advisor.companyName"></a></div>
                    <div class="-location" v-html="advisor.location"></div>
                </div>
                <div class="-links pt-2 pt-md-1">
                    <ul v-if="advisor.socialMedia.length" class="list-unstyled list-inline mb-0">
                        <li v-for="(website, index) in advisor.socialMedia" :key="index"><a :href="website.siteUrl" class="text-decoration-none" target="_blank" rel="external"><i :class="getSocialMediaIconClass(website.siteName)"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="sticky-advisor-header bg--white justify-content-sm-center">
                <div class="-info context-dark">
                    <div class="h2" v-html="advisor.advisorName"></div>
                </div>
                <div class="d-flex flex-row justify-content-lg-between align-items-center my-2">
                    <div class="d-none d-xl-block">
                        <ul class="tab-nav-container">
                            <li id="tab-about" class="-active"><button @click="jumpToSection('about')">About Me</button></li>
                            <li v-if="placesVisitedMapConfig.mapMarkers.length" id="tab-places"><button @click="jumpToSection('places')">Places Visited</button></li>
                            <li v-if="advisor.reviewsCount > 0" id="tab-reviews"><button @click="jumpToSection('reviews')">Reviews &amp; Recommendations</button></li>
                        </ul>
                    </div>
                    <div v-if="advisor.isAcceptingNewClients" class="-contact-button">
                        <advisor-contact-button-component class="-inline" :advisor="advisorContactButtonInfo(advisor)" event-name="Advisor Detail" button-style="sub-head"></advisor-contact-button-component>
                    </div>
                </div>
            </div>
            <section id="about">
                <div class="product-detail-overview">
                    <div class="-info">
                        <div v-if="advisor.companyLogoUrl" class="d-block d-md-none my-3 text-center -logo">
                            <a :href="advisor.agencyUrl"><img :src="advisor.companyLogoUrl" :title="advisor.icPrefix + advisor.companyName" :alt="advisor.icPrefix + advisor.companyName" /></a>
                        </div>
                        <h2 class="text--serif fw-normal -jump-target">About Me</h2>
                        <div class="-description mt-3" v-html="advisor.description"></div>
                    </div>
                    <div class="-gallery mt-5 mt-md-0 mb-6">
                        <div v-if="advisor.companyLogoUrl" class="d-none d-md-block mb-4 text-center -logo">
                            <a :href="advisor.agencyUrl"><img :src="advisor.companyLogoUrl" :title="advisor.icPrefix + advisor.companyName" :alt="advisor.icPrefix + advisor.companyName" /></a>
                        </div>
                        <div v-if="advisor.interests.length || advisor.destinations.length">
                            <h4 class="mb-2">Travel Specialties</h4>
                            <div class="row mb-3">
                                <div v-if="advisor.interests.length" class="col-6">
                                    <b>Interests</b><br />
                                    <ul class="list-unstyled mt-half">
                                        <li v-for="(interest, index) in advisor.interests" :key="index" v-html="interest"></li>
                                    </ul>
                                </div>
                                <div v-if="advisor.destinations.length" class="col-6">
                                    <b>Destinations</b><br />
                                    <ul class="list-unstyled mt-half">
                                        <li v-for="(destination, destIndex) in advisor.destinations" :key="destination" :class="[(destIndex > 4 && !showExtraDestinations) ? 'd-none' : '']"
                                            v-html="destination"></li>
                                        <li v-if="advisor.destinations.length > 5 && !showExtraDestinations"><button class="btn btn-link btn-sm p-0" @click="showExtraDestinations = true">View All</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <template v-if="languagesCSV">
                            <h4>Languages</h4>
                            <div class="mb-3" v-html="languagesCSV"></div>
                        </template>
                        <template v-if="advisor.sellingTravelSince">
                            <h4>Planning Travel Since:</h4>
                            <div class="mb-3" v-html="advisor.sellingTravelSince"></div>
                        </template>
                        <div v-if="advisor.isVVCertifiedHost" class="text-center">
                            <img src="https://virtuoso-prod.dotcms.cloud/images/products/vv-certified-host.png" title="Virtuoso Voyages Certified Host" alt="Virtuoso Voyages Certified Host" width="200" height="118" />
                        </div>
                        <a v-if="isNetworkUser" class="btn btn-primary-emphasis btn-sm d-block mt-3" :href="legacyLink" v-html="legacyLinkLabel"></a>
                    </div>
                </div>
            </section>
            <section v-if="placesVisitedMapConfig.mapMarkers.length" id="places">
                <h2 class="text--serif fw-normal mb-3 mb-xl-0 -jump-target">Places Visited</h2>
                <table class="largeMapTableContainer mx-auto">
                    <tbody>
                        <tr>
                            <td class="largeMapCell">
                                <MapView type="placesVisited" :places-visited-map-config="placesVisitedMapConfig" />
                            </td>
                            <td class="largeMapListCell">
                                <div class="largeMapListDiv">
                                    <table class="largeMapListTable f16"></table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section v-if="advisor.reviewsCount > 0" id="reviews">
                <h2 class="text--serif fw-normal mb-3 -jump-target">Reviews &amp; Recommendations</h2>
                <product-reviews-component v-if="!suppressReviews" :company-id="advisor.id" :product-type="ProductType.ADVISORS" :reviews-data="reviewsData"></product-reviews-component>
            </section>
        </div>
    </div>
    <LogoSplash v-else />
</template>


<script lang="ts">
    import { getProductDetails } from "api/products";
    import { AdvisorContactButton } from "interfaces/advisor";
    import { ProductType } from "interfaces/enums";
    import { ProductDetailsAdvisor } from "interfaces/product";
    import { AdvisorVisitedPlace, ProductDetailsAdvisorResponse } from "interfaces/responses/product-detail-responses";
    import { trackEvent } from "modules/analytics";
    import { initAdvisorSubhead } from "modules/sticky-subhead";
    import { toastError } from "modules/toasts";
    import { isAdvisor, isNetworkUser, virtuosoUser } from "modules/user-info";
    import { generateMediaServerImageUrl } from "services/images";
    import { renderMetadata } from "services/metadata";
    import { formatAdvisorAgencyName, formatLocation } from "services/utilities";
    import * as virtuosoSharedHeader from "virtuoso-shared-web-ui";
    import { defineComponent, nextTick } from "vue";
    import AdvisorContactButtonComponent from "vue-components/advisor-contact-button.vue";
    import LogoSplash from "vue-components/logo-splash.vue";
    import MapView from "vue-components/map-view.vue";
    import ProductReviewsComponent from "vue-components/product-details/product-reviews.vue";

    export default defineComponent({

        components: {
            ProductReviewsComponent,
            AdvisorContactButtonComponent,
            MapView,
            LogoSplash
        },

        props: {
            productId: {
                type: Number,
                default: undefined
            },
            suppressReviews: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                advisor: {} as ProductDetailsAdvisor,
                isReady: false,
                isNetworkUser: isNetworkUser(),
                languagesCSV: "",
                legacyLink: virtuosoSharedHeader.cobrandLink(`/advisors/${this.productId}`),
                legacyLinkLabel: (isAdvisor()) ? "For Advisors: Update Your Profile" : "For Partners: Advanced Results",
                ProductType,
                reviewsData: {},
                showExtraDestinations: false,
                placesVisitedMapConfig: { mapMarkers: []}
            };
        },

        mounted: function (): void {
            if (virtuosoSharedHeader.getCobrandType() === "advisor" && virtuosoUser.cobrandModel.advisorMeid !== this.productId) {
                location.href = virtuosoSharedHeader.cobrandLink(`/travel`);
            } else {
                this.loadAdvisor();
            }
        },

        methods: {
            advisorContactButtonInfo: function (advisor: ProductDetailsAdvisor): AdvisorContactButton {
                return {
                    company: advisor.companyName,
                    companyId: advisor.companyId,
                    email: advisor.advisorEmail,
                    id: advisor.id,
                    name: advisor.advisorName,
                    phone: advisor.advisorPhone,
                    productType: ProductType.ADVISORS
                };
            },

            getSocialMediaIconClass(siteName: string): string {
                let iconClass = "";

                switch (siteName) {
                    case "Facebook":
                        iconClass = "icon-facebook-circle";
                        break;
                    case "Instagram":
                        iconClass = "icon-instagram-circle";
                        break;
                    case "LinkedIn":
                        iconClass = "icon-linkedin-circle";
                        break;
                    case "Pinterest":
                        iconClass = "icon-pinterest-circle";
                        break;
                    case "Twitter":
                        iconClass = "icon-twitter-circle";
                        break;
                    case "YouTube":
                        iconClass = "icon-youtube-circle";
                        break;
                }

                return iconClass;
            },

            jumpToSection(sectionName: string): void {
                $(".tab-nav-container").children().removeClass("-active");
                $(`#tab-${sectionName}`).addClass("-active");
                document.getElementById(sectionName).querySelector("h2")?.scrollIntoView();
                setTimeout(function () {
                    document.getElementById(sectionName).querySelector("h2")?.scrollIntoView(); // In case the sticky header appearing/disappearing caused a shift
                }, 1);
            },

            loadAdvisor(): void {
                getProductDetails(ProductType.ADVISORS, this.productId).then((resultAdvisor: ProductDetailsAdvisorResponse) => {

                    if (resultAdvisor && resultAdvisor.advisorName) {
                        const thisAdvisor: ProductDetailsAdvisor = {
                            advisorEmail: resultAdvisor.advisorEmail,
                            advisorFirstName: resultAdvisor.advisorFirstName,
                            advisorLastName: resultAdvisor.advisorLastName,
                            advisorName: resultAdvisor.advisorName,
                            advisorPhone: resultAdvisor.officePhone,
                            agencyUrl: virtuosoSharedHeader.cobrandLink(`/agencies/${resultAdvisor.companyId}/${virtuosoSharedHeader.slugify(resultAdvisor.companyName)}`),
                            companyId: resultAdvisor.companyId,
                            companyLogoUrl: resultAdvisor.companyLogo,
                            companyName: resultAdvisor.companyName,
                            dbaName: resultAdvisor.advisorDoingBusinessAs,
                            description: resultAdvisor.advisorAboutMe,
                            destinations: resultAdvisor.advisorSpecialtyCountries || [],
                            featuredImageUrl: generateMediaServerImageUrl(resultAdvisor.advisorProfileImageUrl, { width: 200 }),
                            icPrefix: formatAdvisorAgencyName(resultAdvisor.companyName, resultAdvisor.advisorIsIndependentContractor, resultAdvisor.advisorDoingBusinessAs, true),
                            id: resultAdvisor.advisorMasterEntityId,
                            interests: resultAdvisor.advisorInterestTypes || [],
                            isAcceptingNewClients: resultAdvisor.isAdvisorAcceptingNewClients,
                            isIC: resultAdvisor.advisorIsIndependentContractor,
                            isVVCertifiedHost: resultAdvisor.isVVCertifiedHost,
                            languages: resultAdvisor.advisorLanguages || [],
                            location: "", // Derived below
                            reviewsCount: resultAdvisor.totalActiveReviews || 0,
                            reviewsPercent: resultAdvisor.totalRecommendedPercent || 0,
                            sellingTravelSince: Number(resultAdvisor.advisorSellingTravelSinceYear) || null,
                            socialMedia: [] // Derived below
                        };

                        // Advisor Address
                        if (resultAdvisor.profileAddresses && resultAdvisor.profileAddresses.length) {
                            const primaryAddress = resultAdvisor.profileAddresses.find((item) => item.typeName === "Primary");
                            if (primaryAddress) {
                                thisAdvisor.location = formatLocation(
                                    primaryAddress.city,
                                    primaryAddress.state,
                                    primaryAddress.country
                                );
                            }
                        }

                        this.languagesCSV = thisAdvisor.languages.join(", ");

                        // Social Media
                        if (resultAdvisor.profileWebSites && resultAdvisor.profileWebSites.length) {
                            (resultAdvisor.profileWebSites).forEach((website) => {
                                if (website.typeName && website.typeName !== "Primary" && website.link) {
                                    thisAdvisor.socialMedia.push({
                                        siteName: website.typeName,
                                        siteUrl: website.link
                                    });
                                }
                            });
                        }

                        // Reviews
                        if (thisAdvisor.reviewsCount > 0) {
                            this.reviewsData = {
                                percent: `<b>${thisAdvisor.reviewsPercent}% Recommended</b>`,
                                count: `<b>${thisAdvisor.reviewsCount} Review${virtuosoSharedHeader.getPlural(thisAdvisor.reviewsCount)}</b>`
                            };
                        }


                        // Places Visited map data
                        const placesVisited: AdvisorVisitedPlace[] = resultAdvisor.advisorVisitedPlaces || [];

                        if (placesVisited.length) {
                            placesVisited.forEach((place) => {
                                this.placesVisitedMapConfig.mapMarkers.push({
                                    Id: place.pointOfInterestId,
                                    PushpinLabel: "", // Always blank?
                                    SequenceLabel: `${place.pointOfInterestId}`,
                                    Longitude: place.longitude,
                                    Latitude: place.latitude,
                                    InfoWindowHtml: `<h3>${place.placeName}, ${place.countryName}</h3><div>Last Trip: ${place.lastYearVisited}<br>Number of Trips: ${place.numOfVisits}`,
                                    Title: `${place.placeName}, ${place.countryName}`,
                                    CountryCodeISO2: place.countryCodeISO2,
                                    CountryId: place.countryId,
                                    Date: `${place.lastYearVisited}`,
                                    NumOfVisits: place.numOfVisits
                                });
                            });
                        }


                        this.advisor = thisAdvisor;

                        renderMetadata({
                            title: thisAdvisor.advisorName
                        });

                        this.isReady = true;

                        // Apply sticky subhead and highlighted subnav observers
                        nextTick(function () {
                            initAdvisorSubhead();
                        });

                        // GA4 tracking
                        const getVirtuosoAdvisorSearchIndexValue = sessionStorage.getItem(`VirtuosoAdvisorSearchIndex_${this.productId}`);
                        const advisor: ProductDetailsAdvisor = this.advisor;

                        if (getVirtuosoAdvisorSearchIndexValue) {
                            sessionStorage.removeItem(`VirtuosoAdvisorSearchIndex_${this.productId}`);
                        }

                        trackEvent("view_item",
                            {
                                ...((getVirtuosoAdvisorSearchIndexValue) && { index: parseInt(getVirtuosoAdvisorSearchIndexValue, 10) }), // should add index with value if session key existed
                                item_id: `${this.productId}`,
                                item_name: advisor.advisorName,
                                item_brand: (advisor.isIC) ? advisor.dbaName : advisor.companyName,
                                item_category: "Advisor",
                                item_category2: advisor.location,
                                item_category3: `${advisor.reviewsCount}`,
                                item_category4: (advisor.isVVCertifiedHost) ? "virtuoso voyages" : "",
                                item_variant: (advisor.isIC) ? "independent contractor" : "advisor",
                                affiliation: (advisor.isIC) ? advisor.companyName : ""
                            });
                    } else {
                        this.redirectOnError();
                    }
                }, () => this.redirectOnError());
            },

            redirectOnError(): void {
                toastError("Record not found or you don't have access to view it");
                setTimeout(function () {
                    location.href = virtuosoSharedHeader.cobrandLink(`/travel/advisors`);
                }, 3000);
            }
        }

    });
</script>
