import { ProductType } from "interfaces/enums";
import { captureRedirectData } from "modules/analytics";
import { cobrandLink } from "virtuoso-shared-web-ui";
import { createApp } from "vue";
import { mountApp } from "vue-app";
import ProductDetailsComponent from "vue-components/product-details/product-details.vue";


if (window.VIRTUOSO.productType && window.VIRTUOSO.productId) {
    const app = createApp(ProductDetailsComponent,
        {
            catalogType: window.VIRTUOSO.productType,
            productId: window.VIRTUOSO.productId
        }
    );
    mountApp(app, "page-app");
} else {
    captureRedirectData();
    if (window.VIRTUOSO.productType) {
        const redirUrl = (window.VIRTUOSO.productType === ProductType.ADVISORS) ? "/travel/advisors" : `/travel/luxury-${window.VIRTUOSO.productType}`;
        console.error("Product ID not provided");
        location.href = cobrandLink(redirUrl);
    } else {
        console.error("Product Type not provided");
        location.href = cobrandLink("/travel");
    }
}

